<!--component html goes here -->
<!--header start----------------------------------------------------------------- -->
<div class id="HOME">
    <section class="agency header" id="header"
        [ngStyle]="{'background-image': 'url(assets/images/account/slider1.png)'}">
        <div class="agency bg">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="center-text">
                            <div>
                                <div class="header-text">
                                    <h1 class="text-uppercase"><span class="bold-text">Accounting Software</span></h1>
                                </div>
                                <div class="header-sub-text">
                                    <p>Account Management Software Is A Type Of Software That Helps Businesses And
                                        Individuals Keep Track Of Their Financial Transactions, Monitor Expenses, Manage
                                        Budgets, And Maintain Accurate Records Of Their Financial Activities
                                    </p>
                                </div>
                                <div class="link-horizontal">
                                    <ul>
                                        <li>
                                            <a class=" btn btn-default btn-gradient text-white">request a quote</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="set-abs abs-horizon-center girl-boy">
            <img src="assets/images/account/img.png" alt="" class="img-fluid" data-tilt data-tilt-max="5"
                data-tilt-perspective="500" data-tilt-speed="400">
            <div class="plus-container plus-container6">
                <div class="plus white">
                </div>
            </div>
            <div class="plus-container plus-container7">
                <div class="plus white">
                </div>
            </div>
            <div class="plus-container plus-container8">
                <div class="plus white">
                </div>
            </div>
            <div class="circle-container">
                <div class="gradient-circle-big">
                </div>
            </div>
            <div class="circle-container small">
                <div class="gradient-circle-small">
                </div>
            </div>
        </div>
        <div class="plus-container">
            <div class="plus">
            </div>
        </div>
        <div class="plus-container plus-container2">
            <div class="plus plus-medium">
            </div>
        </div>
        <div class="plus-container plus-container3">
            <div class="plus plus-small">
            </div>
        </div>
        <div class="plus-container plus-container4">
            <div class="plus plus-small">
            </div>
        </div>
        <div class="plus-container plus-container5">
            <div class="plus plus-medium">
            </div>
        </div>
        <div class="animated-bg"><i></i><i></i><i></i></div>
    </section>
</div>
<!--header end------------------------------------------------------------------- -->
<!-- //////////////////////////////////////////////////////////////////////////// -->
<!--feature css start------------------------------------------------------------ -->
<div class id="FEATURE">
    <section class="services" id="service"
        [ngStyle]="{'background-image': 'url(assets/images/account/background.png)'}">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
                    <div class="title">
                        <div class="main-title">
                            <h2 style="font-size: 30px; font-weight: 500; letter-spacing: 1px;">FEATURE</h2>
                        </div>
                        <div class="sub-title">
                            <p class="para">Take a look at the many different features that FastAccounts offers and
                                learn more about how they can help take your business to the next level</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4" *ngFor="let data of services">
                    <div class="service service-overlay" data-tilt data-tilt-max="6" data-tilt-speed="400"
                        data-tilt-perspective="500">
                        <div class="img-block">
                            <img [src]="data.img" alt="" class="service-img img-fluid"
                                style="width: 80px; height: 80px;">
                        </div>
                        <div class="service-feature">
                            <h4 class="feature-text">{{data.title}}</h4>
                            <p>{{data.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<!--feature css end-------------------------------------------------------------- -->
<!-- //////////////////////////////////////////////////////////////////////////// -->
<!-- client start---------------------------------------------------------------- -->
<div class id="CLIENTS">
    <section id="clients" class=" saas1 howitwork" routerLink="/clients"
        [ngStyle]="{'background-image': 'url(assets/images/account/slider.png)'}" path="/CLIENT">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <div class="title">
                        <img src="assets/images/saas1/title-logo.png" alt="title-logo" class="img-fluid">
                        <div class="main-title">
                            <h2>
                                <span class="theme-color">Our </span>Client<span class="theme-color"></span><span
                                    class="theme-color"></span>
                            </h2>
                        </div>
                        <hr>
                    </div>
                </div>
                <div class="col-md-12 work-tab">
                    <ul class="nav nav-pills justify-content-center " id="pills-tab" role="tablist">
                        <li class="nav-item  text-center" *ngFor="let data of nav">
                            <a class="nav-link" id="pills-work1-tab" [ngClass]="{'active show': work == data.title }"
                                (click)="openWorkSlide(data.title)">
                                <img [src]="data.img" alt="tab-image-1">
                                <h6>{{data.title}}</h6>
                                <span></span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</div>
<br><br>
<br><br>
<!-- client end------------------------------------------------------------------ -->
<!-- //////////////////////////////////////////////////////////////////////////// -->
<!-- price-plan Start------------------------------------------------------------ -->
<br><br>
<br><br>
<div class id="PRICING">
    <section class="agency pricing price-bg" id="plan"
        [ngStyle]="{'background-image': 'url(assets/images/account/bg.png)'}">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
                    <div class="title">
                        <div class="main-title">
                            <h2 style="font-size: 30px; font-weight: 500; letter-spacing: 1px; color: white;">Our Plans
                            </h2>
                        </div>
                        <div class="sub-title">
                            <p class="para" style="color: white; font-weight: 300; font-size: 18px;">Guide to setup and
                                configuration. You can present below <span
                                    style="color: #000000; font-weight: 300; font-size: 18px"> a guide and a</span>
                                description of how your system configuration works and add some animated screens</p>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <owl-carousel-o [options]="pricingcarouselOptions" class="pricing-slider price-margin">
                        <ng-container *ngFor="let price of pricing">
                            <ng-template carouselSlide class="item">
                                <div class="price-container shadows">
                                    <div class="price-feature-container">
                                        <div class="feature-text">
                                            <span class="flaticon-{{price.icon}} feature-icon"></span>
                                            <h3>{{price.package}}</h3>
                                            <hr class="set-border">
                                        </div>
                                        <div class="price-value">
                                            <h6 class="price">RS:<span class="large">{{price.price1}}</span>/One Time
                                            </h6>
                                            <h6 class="price">RS:<span class="large">{{price.price2}}</span>/monthly
                                            </h6>
                                        </div>
                                        <div class="price-features font-primary">
                                            <h5 class="price-feature">{{price.feature1}}</h5>
                                            <h5 class="price-feature">{{price.feature2}}</h5>
                                            <h5 class="price-feature">{{price.feature3}}</h5>
                                            <h5 class="price-feature">{{price.feature4}}</h5>
                                        </div>
                                        <a href="javascript:void(0)" class="btn btn-default btn-white">{{price.btn}}</a>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </section>
</div>
<!-- price-plan end-------------------------------------------------------------- -->
<!-- //////////////////////////////////////////////////////////////////////////// -->
<!--workflow start--------------------------------------------------------------- -->
<div class id="WORKFLOW">
    <section class="saas2 brand" [ngStyle]="{'background-image': 'url(assets/images/account/background.png)'}">
        <div class="col-md-10 offset-md-1 col-12">
            <div class="container saas2-services">
                <div class="row">
                    <div class="col-md-10 offset-md-1">
                        <div class="title">
                            <div class="main-title">
                                <h2>Workflow</h2>
                            </div>
                            <div class="sub-title">
                                <p class="sub-title-para" style="font-size: 20px; font-weight:500;">Supercharge your
                                    Workflow with a library of Optional APPs.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-8 col-md-7">
                        <div class="image-container">
                            <owl-carousel-o [options]="brandcarouselOptions">
                                <ng-container *ngFor="let brands of brands">
                                    <ng-template carouselSlide>
                                        <a href="javascript:void(0)">
                                            <img [src]="brands.img1" alt="">
                                        </a>
                                    </ng-template>
                                </ng-container>
                            </owl-carousel-o>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-5">
                        <owl-carousel-o [options]="brandcarouselOptions" class="brand-slider">
                            <ng-container *ngFor="let brand of brands">
                                <ng-template carouselSlide class="item">
                                    <a href="javascript:void(0)">
                                        <img [src]="brand.img2" alt="" class="img-fluid">
                                        <h4>{{brand.title}}</h4>
                                        <p>{{brand.description}}</p>
                                    </a>
                                </ng-template>
                            </ng-container>
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<!-- workflow end---------------------------------------------------------------- -->
<!-- //////////////////////////////////////////////////////////////////////////// -->
<!-- form demo start------------------------------------------------------------- -->
<section class="app2 about format" id="DEMO">
    <div class="container">
        <div class="col-md-10 offset-md-1">
            <div class="title title2">
                <h6 class="font-primary borders main-text text-uppercase"><span style="color: blue;">Demo &
                        Proposals</span></h6>
                <div class="sub-title">
                    <h2 class="title-text text-capitalize text-center"
                        style="font-size: 25px; font-weight: 500; letter-spacing: 1px;">Demo Request</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-5 counters set-height">
                <img src="assets/images/account/image.png" alt="" class="img-fluid demomovingimg">
            </div>
            <div class="col-sm-7 counters">
                <div class="abouts  center-text">
                    <div>
                        <div class="row"
                            style="box-shadow:0 5px 35px rgba(0, 0, 0, 15); padding: 50px; border-radius: 20px;">
                            <div class="typography-box">
                                <div class="typo-content">
                                    <form [formGroup]="myForm" (ngSubmit)="saving()">
                                        <div class="form-row">
                                            <div class="col-12 mb-2">
                                                <input type="radio" name="RequestType" id="1" value="Proposal Quotation"
                                                    formControlName="requestType">
                                                <label for="one"> Proposal Quotation</label>
                                            </div>
                                            <div class="col-12 mb-2">
                                                <input type="radio" name="RequestType" id="2" value="Demo request"
                                                    formControlName="requestType">
                                                <label for="two"> Demo request</label>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-12 mb-2">
                                                <input type="text" class="form-control" id="requestByName"
                                                    placeholder="Enter Your name" required="true" type="text"
                                                    formControlName="requestByName">
                                            </div>
                                            <div class="col-12 mb-2">
                                                <input type="text" class="form-control" id="companyName"
                                                    placeholder="Enter Company name" required="true" type="text"
                                                    formControlName="companyName">
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-12 mb-2">
                                                <input type="text" class="form-control" id="mobile"
                                                    placeholder="Enter Mobile Number" required="true" type="number"
                                                    formControlName="mobile">
                                            </div>
                                            <div class="col-12 mb-2">
                                                <input type="text" class="form-control" id="email"
                                                    placeholder="Your Email" required="true" type="email"
                                                    formControlName="email">
                                            </div>
                                            <div class="col-12 mb-2">
                                                <textarea class="form-control" placeholder="Write Your Message"
                                                    id="message" rows="5" formControlName="message"
                                                    type="text"></textarea>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <a class="btn btn-default btn-gradient m-t-50" (click)="saving()">Submit</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Demo end-------------------------------------------------------------------- -->
<!-- //////////////////////////////////////////////////////////////////////////// -->
<!-- faq section start----------------------------------------------------------- -->
<div class id="FAQ">
    <section id="faq" class="saas1 faq testimonial-bg"
        [ngStyle]="{'background-image': 'url(assets/images/account/slider1.png)'}"
        style="background-position: center; background-size: cover; object-fit: cover;">
        <div class="container">
            <div class="row">
                <div class="col-md-8">
                    <div class="faq-block">
                        <div>
                            <img src="assets/images/saas1/title-logo.png" alt="title-logo" class="img-fluid"
                                style="float: left; margin-top: 20px; margin-right: 10px; width: 55px; height: 25px;">
                            <h3 class="frequent-text">
                                Frequently Asked Questions
                            </h3>
                            <div class="accordion faq" id="accordionExample">
                                <div class="card">
                                    <ngb-accordion [closeOthers]="true" activeIds="static">
                                        <ngb-panel id="static-1">
                                            <ng-template ngbPanelTitle>
                                                <a>
                                                    <div class="fa fa-angle-right rotate"></div>
                                                </a>
                                                <p>
                                                    Do I need to make a payment?
                                                </p>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <p>
                                                    No one cares about products. People care about ideas. Is a
                                                    product an idea?
                                                    Noup. Is a brand? A good one is. No one cares about products.
                                                    People care about ideas. Is a product an idea?
                                                    Noup. Is a brand? A good one is.
                                                </p>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-2">
                                            <ng-template ngbPanelTitle>
                                                <a>
                                                    <div class="fa fa-angle-right rotate"></div>
                                                </a>
                                                <p>
                                                    Do I need to make a payment?
                                                </p>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <p>
                                                    No one cares about products. People care about ideas. Is a
                                                    product an idea?
                                                    Noup. Is a brand? A good one is. No one cares about products.
                                                    People care about ideas. Is a product an idea?
                                                    Noup. Is a brand? A good one is.
                                                </p>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-3">
                                            <ng-template ngbPanelTitle>
                                                <a>
                                                    <div class="fa fa-angle-right rotate"></div>
                                                </a>
                                                <p>
                                                    Do I need to make a payment?
                                                </p>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <p>
                                                    No one cares about products. People care about ideas. Is a
                                                    product an idea?
                                                    Noup. Is a brand? A good one is. No one cares about products.
                                                    People care about ideas. Is a product an idea?
                                                    Noup. Is a brand? A good one is..
                                                </p>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-4">
                                            <ng-template ngbPanelTitle>
                                                <a>
                                                    <div class="fa fa-angle-right rotate"></div>
                                                </a>
                                                <p>
                                                    Do I need to make a payment?
                                                </p>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <p>
                                                    No one cares about products. People care about ideas. Is a
                                                    product an idea?
                                                    Noup. Is a brand? A good one is. No one cares about products.
                                                    People care about ideas. Is a product an idea?
                                                    Noup. Is a brand? A good one is.
                                                </p>
                                            </ng-template>
                                        </ngb-panel>
                                        <ngb-panel id="static-5">
                                            <ng-template ngbPanelTitle>
                                                <a>
                                                    <div class="fa fa-angle-right rotate"></div>
                                                </a>
                                                <p>
                                                    Do I need to make a payment?
                                                </p>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <p>
                                                    No one cares about products. People care about ideas. Is a
                                                    product an idea?
                                                    Noup. Is a brand? A good one is. No one cares about products.
                                                    People care about ideas. Is a product an idea?
                                                    Noup. Is a brand? A good one is.
                                                </p>
                                            </ng-template>
                                        </ngb-panel>
                                    </ngb-accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="faq-img-block">
                        <img src="assets/images/saas1/faq-image.png" class="img-fluid" alt="faq-person">
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<!-- end faq section------------------------------------------------------------- -->
<!--//////////////////////////////////////////////////////////////////////////////-->
<!--footer start----------------------------------------------------------------- -->
<div class id="CONTACT">
    <footer class="saas1 footer2" [ngStyle]="{'background-image': 'url(assets/images/account/slider-banner.jpg)'}"
        style="background-position: center; background-size: cover; object-fit: cover;">
        <div class="container">
            <div class="row">
                <div class="col-md-3">
                    <div class="logo-sec">
                        <div class="footer-title mobile-title">
                            <h3 class="text-white">About Us</h3>
                        </div>
                        <div class="footer-contant">
                            <img src="assets/images/account/bizsoft.png" alt="bridge" class="img-fluid footer-logo"
                                style="width: 150px; object-fit: cover;">
                            <div class="footer-para">
                                <h6 class="text-white para-address">BIZSOFT IS AN INDEX GREAT LEVELER AS A SOFTWARE
                                    HOUSE IN PAKISTAN</h6>
                            </div>
                            <ul class="d-d-flex footer-social social">
                                <li class="footer-social-list">
                                    <a href="https://pk.linkedin.com/company/bizsoft-solutions-pvt-limited"
                                        target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li class="footer-social-list">
                                    <a href="https://twitter.com/i/flow/login?redirect_after_login=%2FPvtBizsoft"
                                        target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                </li>
                                <li class="footer-social-list">
                                    <a href="https://pk.linkedin.com/company/bizsoft-solutions-pvt-limited"
                                        target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                                </li>
                                <li class="footer-social-list">
                                    <a href="https://www.youtube.com/watch?v=a0tKAvNQ0O4" target="_blank"><i
                                            class="fa fa-youtube" aria-hidden="true"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">Help links</h3>
                    </div>
                    <div class="footer-contant">
                        <h5 class="footer-headings">Help links</h5>
                        <div>
                            <ul class="footer-lists">
                                <li class=""><a href="https://bizsoft.pk/" target="_blank">Bizsoft Solution Private
                                        Limited </a></li>
                                <li class=""><a href="https://bizsoft.pk/about.html" target="_blank">About</a></li>
                                <li class=""><a href="https://bizsoft.pk/erpsolutions.html" target="_blank">Enterprice
                                        Resource Planning(ERP)</a></li>
                                <li class=""><a href="https://bizsoft.pk/solutions.html" target="_blank">Solution</a>
                                </li>
                                <li class=""><a href="https://bizsoft.pk/contact.html" target="_blank">Contact
                                        Us</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">Contact Info</h3>
                    </div>
                    <div class="footer-contant">
                        <h5 class="footer-headings">Contact Info</h5>
                        <div>
                            <ul class="footer-lists contacts">
                                <li>
                                    <i aria-hidden="true" class="fa fa-map-marker"></i>
                                    Anum Blessing, Suit No. 723, 7th Floor, Plot Zee KCHS Block 7 & 8 Opposite Old
                                    Duty
                                    Free Shop, Main Shahrah-E-Faisal, Karachi, Pakistan.
                                </li>
                                <li>
                                    <i aria-hidden="true" class="fa fa-phone m-r-15"></i>+92-213-453-7211
                                    |Or Whatsapp +92-301-8222-487
                                </li>
                                <li><i aria-hidden="true" class="fa fa-envelope m-r-15"></i>Info@Bizsoft.Pk
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>
<app-tap-to-top></app-tap-to-top>
<!-- Footer end------------------------------------------------------------------ -->
<!--//////////////////////////////////////////////////////////////////////////////-->
<!--copyright start-------------------------------------------------------------- -->
<div class="saas1 copyright" style="background-color: #000000;">
    <div class="container">
        <div class="row">
            <div class="col-sm-4">
                <div class="link-horizontal center-text">
                    <ul>
                        <li>
                            <a href="#" class="copyright-text op-text" style="color: white;">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="#" class="copyright-text op-text" style="color: white;">Terms &amp;
                                Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-8">
                <div>
                    <h6 class="copyright-text text-white text-right op-text" style="color: white;">Copyright © 2023,
                        Development By BizSoft
                        Solutions (Pvt) Limited. All Rights Reserved.</h6>
                </div>
            </div>
        </div>
    </div>
</div>
<!--copyright end---------------------------------------------------------------- -->
<!--//////////////////////////////////////////////////////////////////////////////-->
<!-- ---------------------------------The End------------------------------------ -->