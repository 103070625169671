<app-loader></app-loader>
<router-outlet>
  <link href='https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css' rel='stylesheet'>
  <header class="app2 header-fixed loding-header  position-fixed custom-scroll nav-lg" style="height: 90px;"
    [ngStyle]="{'background-color':'rgb(173 216 230/ 90%)'}">
    <div style="height: 120px;">
      <mat-toolbar style="align-items: start;">
        <a href="#" class="d-inline-block m-r-auto">
          <img src="../assets/images/account/bizsoft.png" alt="bridge" class="img-fluid"
            style="width: 100px; margin-bottom: 50px; margin-left: 90px;">
        </a>
        <!-- <span class="example-spacer"></span> -->
        <div fxShow="true" fxHide.lt-md="fales">
          <a (click)="action('HOME')" mat-button style="color: white;">HOME</a>
          <a (click)="action('FEATURE')" mat-button style="color: white;">FEATURE</a>
          <a (click)="action('CLIENTS')" mat-button style="color: white;">CLIENTS</a>
          <a (click)="action('PRICING')" mat-button style="color: white;">PRICING</a>
          <a (click)="action('WORKFLOW')" mat-button style="color: white;">WORKFLOW</a>
          <a (click)="action('DEMO')" mat-button style="color: white;">DEMO</a>
          <a (click)="action('FAQ')" mat-button style="color: white;">FAQ</a>
          <a (click)="action('CONTACT')" mat-button style="color: white;">CONTACT</a>
        </div>
        <div fxShow="fales" fxHide.gt-sm="true">
          <a (click)="sidenav.toggle()"><i class="bx bx-menu" id="menu"
              style="color: white; font-size: 30px; font-weight: 500;"></i></a>
        </div>
      </mat-toolbar>
      <mat-sidenav-container fxFlexFill class="example-container">
        <mat-sidenav #sidenav fxLayout="column" style="width: 35%; height: 50%; overflow: auto; margin-left: 225px;">
          <div fxLayout="column" style="background-color: lightblue; opacity: 0.9;">
            <!-- <a (click)="sidenav.toggle()" href="#" mat-button>Close</a> -->
            <a (click)="action('HOME')" mat-button style="color: white;">HOME</a>
            <a (click)="action('FEATURE')" mat-button style="color: white;">FEATURE</a>
            <a (click)="action('CLIENTS')" mat-button style="color: white;">CLIENTS</a>
            <a (click)="action('PRICING')" mat-button style="color: white;">PRICING</a>
            <a (click)="action('WORKFLOW')" mat-button style="color: white;">WORKFLOW</a>
            <a (click)="action('DEMO')" mat-button style="color: white;">DEMO</a>
            <a (click)="action('FAQ')" mat-button style="color: white;">FAQ</a>
            <a (click)="action('CONTACT')" mat-button style="color: white;">CONTACT</a>
          </div>
        </mat-sidenav>
      </mat-sidenav-container>
    </div>
  </header>
</router-outlet>
<div class="theme-pannel-main" *ngIf="url!='/landing'">
  <ul>
    <li class="sidebar-btn dark-light-btn" *ngIf="customize.data.settings.layout_version == 'dark'"
      (click)="customizeLayoutVersion('light')">
      <a href="javascript:void(0)" class="dark-light"><span class="theme-layout-version">Light</span></a>
    </li>
  </ul>
</div>