// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { AccountbizsoftwebComponent } from './accountbizsoftweb.component';
import { NgxMasonryModule } from 'ngx-masonry';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { LightboxModule } from 'ng-gallery/lightbox';
import { CommonModule } from '@angular/common';
import { LayoutsRoutingModule } from '../layouts-routing.module';
import { SwiperModule } from 'swiper/angular';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GalleryModule } from 'ng-gallery';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
    imports: [
        CommonModule,
        LayoutsRoutingModule,
        SwiperModule,
        CarouselModule,
        NgbModule,
        GalleryModule,
        LightboxModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        // Ng5SliderModule,
        NgxMasonryModule,
        FlexLayoutModule,
        MatToolbarModule,
        MatButtonModule,
         MatIconModule,
        MatMenuModule,
        MatSidenavModule,
        MatListModule,

    ],
    declarations: [
        AccountbizsoftwebComponent,
    ],
    exports: [
        AccountbizsoftwebComponent,
    ]
})
export class AccountbizsoftwebModule {

}
