import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
    moduleId: module.id,
    selector: 'accountbizsoftweb',
    templateUrl: 'accountbizsoftweb.component.html',
    styleUrls: ['accountbizsoftweb.component.scss']
})
export class AccountbizsoftwebComponent {

    constructor(private http: HttpClient, private route: ActivatedRoute, private FB: FormBuilder) { }

    ngOnInit() {
        this.initializeForm();
    }

    // header start or end------------------------------------------------------ -->
    //////////////////////////////////////////////////////////////////////////// -->
    // features start----------------------------------------------------------- -->
    services = [
        {
            img: 'assets/images/account/1.png',
            title: 'Real-Time Accounting',
            description: 'With a real-time event-driven accounting system, Bizsoft helps centralize and streamline the management of business events, finance & .'
        },
        {
            img: 'assets/images/account/2.png',
            title: 'Intelligent finance',
            description: 'Leveraged smart and advanced technology, Yonyou improves financial analysis and reporting to help enterprises make more efficient and automated decision.'
        },
        {
            img: 'assets/images/account/3.png',
            title: 'Sales And Purchase',
            description: 'Create professional invoices, sales receipt and quotation. Convert quotation to invoices, store and preview invoices, customer aging invoices, manage receivables and more..'
        },
        {
            img: 'assets/images/account/4.png',
            title: 'Integration',
            description: 'Our understanding of business synergies has led us to make our platform easy to integrate with other software and systems that you may already have in place.'
        },
        {
            img: 'assets/images/account/5.png',
            title: 'Complete Functionality',
            description: 'Designed to be the only online accounting software you’ll ever need, FastAccounts can assist you with all functions of your business..'
        },
        {
            img: 'assets/images/account/6.png',
            title: 'Fully Accessiblex',
            description: 'Anywhere, anytime. Bizsoft is an online accounting software and is therefore designed to be accessible anywhere access and available to you 24/7..'
        },
    ]
    //  feature end----------------------------------------------------------------- -->
    //////////////////////////////////////////////////////////////////////////////// -->
    // client start----------------------------------------------------------------- -->
    public work: any = 'VIP GROUP'
    openWorkSlide(val) {
        this.work = val
    }
    nav = [
        {
            img: 'assets/images/account/s1.png',
            title: 'VIP GROUP'
        },
        {
            img: 'assets/images/account/s4.png',
            title: 'SINO SINDH RESOURCES(Pvt).LTD'
        },
        {
            img: 'assets/images/account/s3.png',
            title: 'REMARK(REAL ESTATE & MARKETING)'
        },
        {
            img: 'assets/images/account/s2.png',
            title: 'SPACE TECHNOLOGY'
        },
        {
            img: 'assets/images/account/s5.png',
            title: 'ZIMMEL ASSOCIATES'
        }
    ]
    // client end------------------------------------------------------------------- -->
    //////////////////////////////////////////////////////////////////////////////// -->
    // pricing start---------------------------------------------------------------- -->
    pricing = [
        {
            icon: "rocket-ship",
            package: "BASIC",
            feature1: "One Company",
            feature2: "One Location",
            feature3: "Minimum 10 Users",
            feature4: "Domain Included",
            price1: "150,000",
            price2: "12,000",
            btn: "purchase"
        },
        {
            icon: "pie-chart",
            package: "STANDARD",
            feature1: "One Company",
            feature2: "One Location",
            feature3: "Minimum 20 Users",
            feature4: "Domain Included",
            price1: "250,000",
            price2: "18,000",
            btn: "purchase"
        },
        {
            icon: "diamond",
            package: "PREMIUM",
            feature1: "One Company",
            feature2: "One Location",
            feature3: "Unlimited Users",
            feature4: "Domain Included",
            price1: "450,000",
            price2: "52,000",
            btn: "purchase"
        },
    ]
    pricingcarouselOptions = {
        items: 3,
        margin: 30,
        nav: false,
        autoplay: false,
        slideSpeed: 300,
        paginationSpeed: 400,
        loop: true,
        dots: false,
        responsive: {
            0: {
                items: 1,
                margin: 10
            },
            576: {
                items: 2,
                margin: 10
            },
            991: {
                items: 2,
                margin: 15
            },
            992: {
                items: 3,
                margin: 15
            },
            1000: {
                items: 3
            }
        }
    }
    // pricing end------------------------------------------------------------------ -->
    //////////////////////////////////////////////////////////////////////////////// -->
    // workflow start--------------------------------------------------------------- -->
    brands = [
        {
            img1: 'assets/images/account/po1.png',
            img2: 'assets/images/account/s1.png',
            title: 'Clean Design',
            description: 'Lorem Ipsum is simply dummy text of the printing and industry.'
        },
        {
            img1: 'assets/images/account/po1.png',
            img2: 'assets/images/account/s2.png',
            title: 'Dedicated Support',
            description: 'Lorem Ipsum is simply dummy text of the printing and industry.'
        },
        {
            img1: 'assets/images/account/po1.png',
            img2: 'assets/images/account/s3.png',
            title: 'Easy Customiable',
            description: 'Lorem Ipsum is simply dummy text of the printing and industry.'
        },
        {
            img1: 'assets/images/account/po1.png',
            img2: 'assets/images/account/s4.png',
            title: 'Multiple Demo',
            description: 'Lorem Ipsum is simply dummy text of the printing and industry.'
        }
    ]
    brandcarouselOptions = {
        items: 4,
        margin: 40,
        autoHeight: true,
        // nav: false,
        dots: false,
        autoplay: true,
        slideSpeed: 800,
        paginationSpeed: 900,
        loop: true,
        responsive: {
            0: {
                items: 1,
                margin: 40
            },
            320: {
                items: 1,
                margin: 30
            },
            360: {
                items: 2,
                margin: 20
            },
            480: {
                items: 1,
                margin: 30
            },
            768: {
                items: 1,
                margin: 30
            },
            992: {
                items: 1
            }
        }
    }
    // workflow end----------------------------------------------------------------- -->
    //////////////////////////////////////////////////////////////////////////////// -->
    // demo start------------------------------------------------------------------- -->
    public formBuilder: any;
    public myForm: FormGroup;
    public submitted: boolean;
    async initializeForm() {
        this.myForm = this.FB.group({
            'id': [''],
            'requestType': ['', [Validators.required]],
            'requestByName': ['', [Validators.required]],
            'companyName': ['', [Validators.required]],
            'mobile': ['', [Validators.required]],
            'email': ['', [Validators.required]],
            'message': [''],
            'sourceurl': [''],
        })
    }
    async saving() {
        debugger
        if (!this.myForm.controls['id'].value) {
            this.myForm.controls['id'].setValue(0)
        }
        this.myForm.controls['sourceurl'].setValue('https://accounting.com.pk/')
        debugger
        if (!this.myForm.controls['requestType'].value) {
            this.myForm.controls['requestType'].setValue('Proposal Quotation')
        } else {
            this.myForm.controls['requestType'].setValue('Demo request')
        }
        await this.http.post(environment.apiURL + 'Customer_Request/save', this.myForm.value).toPromise().then((user: any) => {
            debugger
            if (user.id > 0) {
                this.myForm.controls['id'].setValue(user.id);
                alert(user.messages)
                this.clear();
            } else {
            }
        }).catch((error) => {

            if (error) {
                ;
            }
        });
    }
    async clear() {
        debugger
        this.myForm.controls[''];
        this.formBuilder = []
    }
    // demo end--------------------------------------------------------------------- -->
    //////////////////////////////////////////////////////////////////////////////// -->
}

